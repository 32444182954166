import React, { useEffect, useState } from 'react';
import serviceDataJson from '../data/ServiceData'
import "../styles/services.css";
import FAQSection from '../components/common/FAQ'
import CasinoIcon from '@mui/icons-material/Casino';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import BlurOnIcon from '@mui/icons-material/BlurOn';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AddIcon from '@mui/icons-material/Add';
import { useParams } from 'react-router-dom';

function ServiceComponent(props) {
    const { stringParam } = useParams();
    const [serviceData, setServiceData] = useState(serviceDataJson['customApplication']);

    useEffect(()=>{
        setServiceData(serviceDataJson[stringParam]);
    }, [stringParam])

    return (
        <div className='service-component container-fluid'>
            <div className='service-landing-section row justify-content-center'>
                <div className='serive-header col-11'>{serviceData.heading}</div>
                <div className='service-title col-11  text-align-left'>{serviceData.serviceTagLine}</div>
                <img className='serive-image col-12 col-md-10' src={"https://i.imgur.com/fZBHSqG.png"}></img>
                <div className='why-service-explanation col-10 col-md-8'>{serviceData.serviceText}</div>
            </div>

            {/* <div className='fixed-scroll-section row'>
                <div className='fixed-scroll-left-section col-12 col-md-5'>{serviceData.heading}</div>
                <div className='fixed-scroll-right-section col-12 col-md-7'>
                    {serviceData.serviceCategory.map(categoryData => {
                        return(
                            <div>
                                <div className='fs-right-section-header'> <AddIcon/> {categoryData.categoryName}</div>
                                <div className='fs-right-section-body px-3'>{categoryData.explanation}</div>
                            </div>
                        )
                    })}
                </div>
            </div> */}

            <div className='faq-type-section container-fluid'>
                <FAQSection contentData = {serviceData.serviceCategory}></FAQSection>
            </div>

            <h1 className='our-header'>{serviceData.BenefitsHeading}</h1>
            <div className='four-cards-section'>
                <div className='left-card-section'>
                    <div className='color-card color-card-1'>
                        <CasinoIcon sx={{fontSize:50}}/>
                        <div className='color-card-header'>{serviceData.ourbenefits[0].benefitHeader}</div>
                        <div className='color-card-body'>{serviceData.ourbenefits[0].benefitBody}</div>
                    </div>
                    <div className='color-card color-card-2'>
                        <AutoFixHighIcon sx={{fontSize:50}}/>
                        <div className='color-card-header'>{serviceData.ourbenefits[1].benefitHeader}</div>
                        <div className='color-card-body'>{serviceData.ourbenefits[1].benefitBody}</div>
                    </div>
                </div>

                <div className='right-card-section'>
                    <div className='color-card color-card-3'>
                        <BlurOnIcon sx={{fontSize:50}}/>
                        <div className='color-card-header'>{serviceData.ourbenefits[2].benefitHeader}</div>
                        <div className='color-card-body'>{serviceData.ourbenefits[2].benefitBody}</div>
                    </div>
                    <div className='color-card color-card-4'>
                        <WorkspacesIcon sx={{fontSize:50}}/>
                        <div className='color-card-header'>{serviceData.ourbenefits[3].benefitHeader}</div>
                        <div className='color-card-body'>{serviceData.ourbenefits[3].benefitBody}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ServiceComponent;