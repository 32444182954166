import React, { useEffect, useState } from 'react';
import '../styles/homePage.css'
import homePageData from '../data/homePageData'
import SolidColorButton from '../components/common/SolidColorButton';
import OutlineButton from './common/OutlineButton';
import companyLogo from '../assets/svg/Contique-Global.svg';
import howWeWork from '../assets/images/howWeWork.png'
import LeaderboardIcon from '@mui/icons-material/Leaderboard';

function HomePage(props) {
    let cardColorIndex = 0;
    
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
      });

      const dynamicText = `${homePageData.landingSection.line1}`; 
    //    <br/> ${homePageData.landingSection.line2}`;

      const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
      };

      const handleSubmit = (event) => {
        event.preventDefault();
        setFormData({
          name: '',
          email: '',
          message: ''
        });
      };

    return (
        <div className='home-page'>
            <div className='intro-section d-flex flex-column'>
                <div className='company-tagline d-flex'>
                    {/* <LeaderboardIcon sx={{color:"red"}}/> */}
                    <div className='px-1 company-tagline-text'>A full stack digital agency</div>
                </div>
                
                <div className='landing-section-text col-md-9' dangerouslySetInnerHTML={{ __html: dynamicText }} />
                <div className='work-with-us-circle'>
                    <div className='work-with-us' dangerouslySetInnerHTML={{ __html: 'work <br/> with us?' }}/>
                </div>
            </div>

            <div className='mini-about-us-section container'>
                <div className='mini-about-us-header our-header'>{homePageData.knowMoreSection.header}</div>
                <div className='mini-about-us-body px-4'>{homePageData.knowMoreSection.body}</div>
                <SolidColorButton className="about-us-button" redirectTo={"aboutUs"} buttonName = {homePageData.knowMoreSection.buttonName}/>
            </div>
            <div className='our-clients-section'>
              <div className='our-header'>our client</div>
                <div className='our-clients-container'>
                    <div className='oc-slider1'>
                        {homePageData.clientSection.row1ImageUrls.map((imageUrl, index) =>{
                            return(
                                <img src={imageUrl} className="our-client"/>
                            )
                        })}
                        {homePageData.clientSection.row1ImageUrls.map((imageUrl, index) =>{
                            return(
                                <img src={imageUrl} className="our-client"/>
                            )
                        })}
                    </div>

                    <div className='oc-slider2'>
                        {homePageData.clientSection.row2ImageUrls.map((imageUrl, index) =>{
                            return(
                                <img src={imageUrl} className="our-client"/>
                            )
                        })}
                        {homePageData.clientSection.row2ImageUrls.map((imageUrl, index) =>{
                            return(
                                <img src={imageUrl} className="our-client"/>
                            )
                        })}
                    </div>
                 </div>
            </div>

            <div className='service-card-section container-fluid'>
                <div className='our-header'>our services</div>
                    {homePageData.cardsSection.map((card, index)=>{
                        cardColorIndex = cardColorIndex+1;
                        if(cardColorIndex>3){
                            cardColorIndex = 1;
                        }
                    return (
                        <div className={`service-card d-flex flex-column justify-content-center card${cardColorIndex}`} style={{zIndex:{index}}}>
                            <div className='row d-flex justify-content-left align-items-center mb-4 mx-3 mx-md-0'>
                                <div className='service-card-header col-5 col-md-7'>{homePageData.cardsSection[index].serviceName}</div>
                                <OutlineButton className = "col-6 col-md-3 service-card-button" redirectTo={homePageData.cardsSection[index].serviceId} buttonName = "Know more"/>
                            </div>
                            <div className='row justify-content-around my-md-4'>
                                <div className='col-12 col-md-4 px-5 px-md-0 mb-5 service-card-body justify-content-center'>{homePageData.cardsSection[index].serviceExplanation}
                                {/* <OutlineButton className = "service-card-button mx-5 mt-3" redirectTo={homePageData.cardsSection[index].serviceId} buttonName = "Know more"/> */}
                                </div>
                                <div className=' col-12 col-md-4 px-5 px-md-0 d-flex flex-column justify-content-left'>
                                    {homePageData.cardsSection[index].servicesType.map((category, index) =>{
                                        return <div className='mb-2 service-card-category'>- {category}</div>
                                    })}
                                    {/* <OutlineButton className = "service-card-button mt-3" redirectTo={homePageData.cardsSection[index].serviceId} buttonName = "Know more"/> */}
                                </div>
                            </div>
                            {/* <div className="row justify-content-center">
                                <OutlineButton className = "col-5 col-md-3 service-card-button" redirectTo={homePageData.cardsSection[index].serviceId} buttonName = "Know more"/>
                            </div> */}
                        </div>
                    );
                })}
                </div>

{/* 
            <div className='service-card-section container-fluid'>
                <div className='our-header'>our services</div>
                    {homePageData.cardsSection.map((card, index)=>{
                        cardColorIndex = cardColorIndex+1;
                        if(cardColorIndex>3){
                            cardColorIndex = 1;
                        }
                    return (
                        <div className={`service-card align-items-center card${cardColorIndex}`} style={{zIndex:{index}}}>
                            <div className='row d-flex justify-content-between align-items-center mt-2 mb-4'>
                                <div className='service-card-header col-10 col-md-8'>{homePageData.cardsSection[index].serviceName}</div>
                                <OutlineButton className = "col-6 col-md-3 service-card-button" redirectTo={homePageData.cardsSection[index].serviceId} buttonName = "Know more"/>
                            </div>
                            <div className='row justify-content-around my-md-4'>
                                <div className='col-10 col-md-5 mb-5 service-card-body'>{homePageData.cardsSection[index].serviceExplanation}</div>
                                <div className=' col-10 col-md-4 d-flex flex-column justify-content-left'>
                                    {homePageData.cardsSection[index].servicesType.map((category, index) =>{
                                        return <div className='mb-2 service-card-category'>- {category}</div>
                                    })}
                                </div>
                            </div>
                        </div>
                    );
                })}
                </div> */}
            <div style={{height:`${(homePageData.cardsSection.length * 75) + 25}vh`, width:"100vw"}}></div>

            <div className='row justify-content-center align-items-center how-we-work-section'>
                <div className='justify-content-end col-8 col-md-4 m-5'>
                    <img src = {howWeWork} className='image-placeholder'/>
                </div>
                <div className='col-10 col-md-4 align-items-center'>
                    <div className='how-we-work-header py-2'>{homePageData.howWeDoSection.heading}</div>
                    <div className='how-we-work-body py-2'>{homePageData.howWeDoSection.body}</div>
                </div>
            </div>

            <div className='contact-section align-items-center'>
            <div className='our-header'>how to reach us</div>
                <form action="https://api.web3forms.com/submit" method="POST">
                    <input type="hidden" name="access_key" value="ee7adff6-957c-4358-945e-337b89900556"></input>
                    <div className='row justify-content-around'>
                        <input className= "input-box col-10 col-md-5 my-2 my-md-4 py-3 px-4" type='text' name='name' placeholder='First name*' required></input>
                        <input className= "input-box col-10 col-md-5 my-2 my-md-4 py-3 px-4" type='text' name='name' placeholder='Last name'></input>
                        <input className= "input-box email-input col-10 col-md-5 my-2 my-md-4 py-3 px-4" type='text' name='name' placeholder='Email'></input>
                        <input className= "input-box col-10 col-md-5 my-2 my-md-4 py-3 px-4" type='text' name='name' placeholder='Phone*' required></input>
                        <textarea className= "h-10 input-box col-10 col-md-11 my-1 my-md-4 py-3 px-4" type='text area' name='name' placeholder='How can I help you?'></textarea>
                        <input type="hidden" name="redirect" value="https://contiqueglobal.com/"></input>
                    </div>
                    <SolidColorButton type="submit" className="form-submit-button" buttonName = "Contact us"/>
                </form>
            </div>
        </div>
         
    );
}

export default HomePage;



// import PropTypes from 'prop-types';
// import styled from '@emotion/styled';
// import { typography } from '../../shared/styles';

// const fontStyles = ({fontFamily = 'primary', size = 'l1', weight, color = 'inherit'}) => {
//   return `
//     font-family: ${typography.type[fontFamily]};
//     font-size: ${typography.size[size]};
//     font-weight: ${typography.weight[weight]};
//     color: ${typography.color[color] ?? 'inherit'};
//   `;
// }

// const layoutStyle = ({lineHeight = '30px', letterSpacing = '-0.04em', margin = 0}) => {
//   return `
//     line-height: ${lineHeight};
//     letter-spacing: ${letterSpacing};
//     margin: ${margin};
//   `
// }

// const getTextStyles = (props) => {
//   const { variant, weight, color } = props;

//   switch (variant) {
//     case 'display1':
//       return styled.div`
//         ${fontStyles({size: 'l3', weight: weight, color: color})}
//         ${layoutStyle({lineHeight: '84px'})}
//       `;
//     case 'display2':
//       return styled.div`
//         font-family: ${typography.type.primary};
//         font-size: ${typography.size.l3};
//         font-weight: ${typography.weight[weight]};
//         color: ${typography.color[color] ?? 'inherit'};
//         line-height: 84px;
//         letter-spacing: -0.04em;
//         margin: 0;
//       `;
//     case 'h1':
//       return styled.h1`
//         font-family: ${typography.type.primary};
//         font-size: ${typography.size.l3};
//         font-weight: ${typography.weight[weight]};
//         color: ${typography.color[color] ?? 'inherit'};
//         line-height: 84px;
//         letter-spacing: -0.04em;
//         margin: 0;
//       `;
      
//     case 'h2':
//       return styled.h2`
//         font-family: ${typography.type.primary};
//         font-size: ${typography.size.l2};
//         font-weight: ${typography.weight[weight]};
//         color: ${typography.color[color] ?? 'inherit'};
//         line-height: 84px;
//         letter-spacing: -0.04em;
//         margin: 0;
//       `;
//     case 'h3':
//       return styled.h3`
//         font-family: ${typography.type.primary};
//         font-size: ${typography.size.l1};
//         font-weight: ${typography.weight[weight]};
//         color: ${typography.color[color] ?? 'inherit'};
//         line-height: 84px;
//         letter-spacing: -0.04em;
//         margin: 0;
//       `;
//     case 'h4':
//       return styled.h4`
//         font-family: ${typography.type.primary};
//         font-size: ${typography.size.l3};
//         font-weight: ${typography.weight[weight]};
//         color: ${typography.color[color] ?? 'inherit'};
//         line-height: 84px;
//         letter-spacing: -0.04em;
//         margin: 0;
//       `;
//     case 'body':
//       return styled.div`
//         font-family: ${typography.type.primary};
//         font-size: ${typography.size.l1};
//         font-weight: ${typography.weight[weight]};
//         color: ${typography.color[color] ?? 'inherit'};
//         line-height: 84px;
//         letter-spacing: -0.04em;
//         margin: 0;
//       `;
//     case 'sub':
//       return styled.div`
//         font-family: ${typography.type.primary};
//         font-size: ${typography.size.l1};
//         font-weight: ${typography.weight[weight]};
//         color: ${typography.color[color] ?? 'inherit'};
//         line-height: 84px;
//         letter-spacing: -0.04em;
//         margin: 0;
//       `;
//     case 'p':
//       return styled.p`
//         font-family: ${typography.type.primary};
//         font-size: ${(props) => typography.size.p1};
//         font-weight: ${typography.weight[weight]};
//         color: ${typography.color[color] ?? 'inherit'};
//         line-height: 84px;
//         letter-spacing: -0.04em;
//         margin: 0;
//       `;
//     default:
//       return styled.p`
//         font-family: ${typography.type.primary};
//         font-size: ${(props) => typography.size[variant] || 'inherit'};
//         font-weight: ${typography.weight[weight]};
//         color: ${typography.color[color] ?? 'inherit'};
//         line-height: 84px;
//         letter-spacing: -0.04em;
//         margin: 0;
//       `;
//   }
// };

// const Text = (props) => {
//   const TextComponent = getTextStyles(props);
//   return <TextComponent {...props} />;
// };

// Text.propTypes = {
//   variant: PropTypes.oneOf([
//     'display1',
//     'display2',
//     'h1',
//     'h2',
//     'h3',
//     'h4',
//     'body',
//     'sub',
//     'p',
//   ]),
//   weight: PropTypes.oneOf([
//     'light',
//     'regular',
//     'semibold',
//     'medium',
//     'bold',
//     'extrabold',
//     'black',
//   ]),
//   color: PropTypes.oneOf(Object.keys(typography.color)),
// };

// Text.defaultProps = {
//   variant: 'body',
//   weight: 'regular',
//   color: 'inherit',
// };

// export default Text;




// import React from 'react';
// import Text from './Text';
// import { typography, color } from '../../shared/styles';

// export default {
//   title: 'Atoms/Text',
//   component: Text,
//   argTypes: {
//     variant: {
//       control: {
//         type: 'select',
//         options: [
//           'display1',
//           'display2',
//           'h1',
//           'h2',
//           'h3',
//           'h4',
//           'body',
//           'sub',
//           'p',
//         ],
//       },
//       defaultValue: 'body',
//     },
//     weight: {
//       control: {
//         type: 'select',
//         options: [
//           'light',
//           'regular',
//           'semibold',
//           'medium',
//           'bold',
//           'extrabold',
//           'black',
//         ],
//       },
//       defaultValue: 'regular',
//     },
//     color: {
//       control: {
//         type: 'select',
//         options: Object.keys(typography.color),
//       },
//       defaultValue: 'inherit',
//     },
//     children: {
//       control: 'text',
//       defaultValue: 'Hello, World!',
//     },
//   },
// };

// const Template = (args) => <Text {...args} />;

// export const CustomText = Template.bind({});
// CustomText.args = {
//   children: 'Custom Text',
//   variant: 'h1',
//   weight: 'bold',
//   color: 'primary',
// };