import React from 'react';
import '../styles/footer.css';
import companyLogo from '../assets/images/Contique-Global-white.png';


function Footer(props) {
  return (
    <footer className='container-fluid footer'>
      <div className='row justify-content-around mx-3 mt-3'>
        <div className='footer-company col-md-2 col-12  pt-4 justify-content-left'>
          <img src ={companyLogo} className='img-logo'/>
        </div>
        <p className='footer-description col-md-3 col-12 pt-4 pb-0'>Contique Global pioneers in digital-first solutions, empowering businesses to advance by crafting robust identities and expertly navigating their technological and marketing requirements.</p>
        <div className='footer-address col-md-2 col-12'>
          <b className='heading-text'>Step in at:</b><br/>
          37, East Abhiramapuram,<br/> Mylapore, Chennai,<br/> Tamil Nadu 600004
        </div>
        <div className='footer-contact col-md-3 col-12'>
          <b className='heading-text'>Dial us:</b><br/>
          <div className='footer-phone'>Phone: (+91) 893 984 7012</div>
          <div className='footer-email'>Email : support@contiqueglobal.com</div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
