import React, { useState } from 'react';
import '../styles/header.css'
import companyLogo from '../assets/images/Contique-Global.png';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { Link } from 'react-router-dom';


function Header(props) {
    const [showHeaderScreen, setShowHeaderScreen]= useState(false);

    const handleMenuClick = () => {
        setShowHeaderScreen(true);
        console.log(showHeaderScreen);
    };

    const handleCrossClick = () => {
        setShowHeaderScreen(false);
        console.log(showHeaderScreen);
    };

    const closeHeaderMenu =()=>{
        console.log("close header menu");
        setShowHeaderScreen(false);
    }


    return (
        <div className={`header-section justify-content-between ${showHeaderScreen ? 'header-section-fixed' : ''}`} style={{marginRight:"0px"}}>
            <div className='row justify-content-between mx-0'>
            <Link className='col-4 col-md-2 mx-5 my-2 justify-content-left' onClick = {closeHeaderMenu} to={"/"} style={{ textDecoration: 'none', color: 'inherit' }}>
                <img className="company-logo" src={companyLogo}></img>
            </Link>
            {showHeaderScreen ? <CloseIcon onClick={handleCrossClick} className="header-close-icon col-2 mx-5" style={{ fontSize: '11vmin', border:"none"}} color="black"/> :<DragHandleIcon  onClick={handleMenuClick} className="menu-icon col-2  mx-5" fontSize="large" color="black" style={{ fontSize: '11vmin'}}  /> }
            {
                showHeaderScreen ? 
                    <div className='header-expanded row px-md-5'>
                        <div className='col-11 col-md-5 mx-5' style={{height:'fit-content'}}>
                            <div className='header-expanded-heading'>our services</div>
                            <Link onClick = {closeHeaderMenu} className="header-content py-2" to={"/services/websiteBuilding"} style={{ textDecoration: 'none', color: 'inherit' }}><ArrowOutwardIcon sx={{color:"grey"}}/>Website Building</Link>
                            <Link onClick = {closeHeaderMenu} className="header-content py-2" to={"/services/customApplication"} style={{ textDecoration: 'none', color: 'inherit' }}><ArrowOutwardIcon sx={{color:"grey"}}/>Custom Application</Link>
                            <Link onClick = {closeHeaderMenu} className="header-content py-2" to={"/services/ECommerceGrowth"} style={{ textDecoration: 'none', color: 'inherit' }}><ArrowOutwardIcon sx={{color:"grey"}}/>E-commerce Growth</Link>
                            <Link onClick = {closeHeaderMenu} className="header-content py-2" to={"/services/B2BLeadGeneration"} style={{ textDecoration: 'none', color: 'inherit' }}><ArrowOutwardIcon sx={{color:"grey"}}/>B2B Lead Generation</Link>
                            <Link onClick = {closeHeaderMenu} className="header-content py-2" to={"/services/performanceMarketing"} style={{ textDecoration: 'none', color: 'inherit' }}><ArrowOutwardIcon sx={{color:"grey"}}/>Performance Marketing</Link>
                            <Link onClick = {closeHeaderMenu} className="header-content py-2" to={"/services/videoProduction"} style={{ textDecoration: 'none', color: 'inherit' }}><ArrowOutwardIcon sx={{color:"grey"}}/>Videos</Link>
                        </div>
                        <div className='col-11 col-md-4 mx-5'>
                            <div className='header-expanded-heading'>culture</div>
                            <Link onClick = {closeHeaderMenu} className="header-content py-2" to={"/aboutUs"} style={{ textDecoration: 'none', color: 'inherit' }}><ArrowOutwardIcon sx={{color:"grey"}}/>About Us</Link>
                            <Link onClick = {closeHeaderMenu} className="header-content py-2" to={"/contactUs"} style={{ textDecoration: 'none', color: 'inherit' }}><ArrowOutwardIcon sx={{color:"grey"}}/>contact us</Link>
                            </div>
                    </div>
                    : ""
            }
            </div>
        </div>
    );
}

export default Header;
