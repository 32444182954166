// Define the theme object with light and dark themes
export const theme = {
    lightTheme: {
         // Light theme colors
        secondary: 'rgb(226, 224, 224)',
        background: "rgb(255,248,248)",
        // highLightColor: "rgb(84, 255, 241)",
        highLightColor: "#1695a9",
        // cardColor1:"rgb(255,47,74)",
        cardColor3: "#95ede6",
        // cardColor2:"rgb(211,113,250)",
        cardColor2: "#ffeda3",
        // cardColor3:"rgb(49,197,254)",
        cardColor1: "#ff908a",
        fontColor: "black",
    },
    darkTheme: {
        // Dark theme colors
        secondary: 'rgb(53, 53, 53)',
        background: "rgb(23, 23, 23)",
        fontColor: "rgb(255, 255, 255)",
    }
};