import logo from './logo.svg';
import './App.css';
import HomePage from '../src/components/HomePage';
import { theme } from './styles/theme.js';
import ServiceComponent from './components/ServiceComponent.js';
import Footer from './components/Footer.js';
import Header from '../src/components/Header.js';
import ScrollToTop from '../src/components/common/ScrollToTop';
import AboutUs from '../src/components/AboutUs.js'
import ContactUs from '../src/components/ContactUs.js'
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';


function App() {

  const ACTIVE_THEME = "lightTheme";

  const cssVariables = Object.entries(theme[ACTIVE_THEME]).map(([key, value]) => {
    return `--${key}: ${value};`;
  });

  document.documentElement.style.cssText = cssVariables.join('');


  return (
    <div className="App">
      <Router>
        <div>
        <Header/>
        <ScrollToTop/>
          <Routes>
            <Route path="/" element={<HomePage/>}/>
            <Route path="/services/:stringParam" element={<ServiceComponent/>}/>
            <Route path="/contactUs" element={<ContactUs/>}/>
            <Route path="/aboutUs" element={<AboutUs/>}/>
          </Routes>
          <Footer/>
        </div>
      </Router>
    </div>
  )
}

export default App;
