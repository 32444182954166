const data = {
    landingSection:
      {
        line1: "Are You Ready to take the Next Big Step?",
        line2: 'Take the Next Big Step?',
      },
      knowMoreSection:{
        header: "Creating Impact for brands",
        body: "We help organisations create powerful identities, impactful strategies and killer marketing communication. We are classified Thinkers and strategists with years of testing, tweaking, and reverse-engineering that has given us an advantage to choose the best.",
        buttonName:"Know more",
      },
      clientSection:{
        row1ImageUrls: ["https://i.imgur.com/HCdOf1l.png","https://i.imgur.com/flh6HJC.png","https://i.imgur.com/tU7NOQ2.png","https://i.imgur.com/tEVNvWF.png","https://i.imgur.com/unTxN9i.png","https://i.imgur.com/AfeohVg.png","https://i.imgur.com/p0HtrsF.png"],
        row2ImageUrls: ["https://i.imgur.com/tU7NOQ2.png","https://i.imgur.com/ylXwEJl.jpg", "https://i.imgur.com/EaoaaMg.png", "https://i.imgur.com/nWckcVW.png","https://i.imgur.com/DymHkag.png","https://i.imgur.com/BF7rEM2.png", "https://i.imgur.com/U8JxcI5.png" ],
      },
      cardsSection:[
        {
            serviceId: "/services/customApplication",
            serviceName: "Custom Application.",
            serviceExplanation: "World-class industry and technical expertise, we build custom applications that you have visualised to reality. We come up with the sheer customer satisfaction in mind, right app development approach & with the dedicated development team for developing highly intriguing applications for digitising your business.",
            servicesType:[
                "Web Application Building","Android/ IOS application development","Strategy Development","Support & Performance monitoring"
            ]
        },
        {
            serviceId: "/services/websiteBuilding",
            serviceName: "Website Building.",
            serviceExplanation: "We design functional interfaces for an aesthetically pleasing digital presence that helps tell your story and boost brand performance. Each website page and app screen relays an intuitive UX/UI design that maximises visitor interaction with the help of data-driven research.",
            servicesType:[
                "Wordpress site development","Shopify store development", "Landing Page Development ","UI / UX Design"
            ]
        },
        {
            serviceId: "/services/ECommerceGrowth",
            serviceName: "E-commerce Growth.",
            serviceExplanation: "We thrive to increase your online sales with strategic marketing, conversion optimization, and tailored solutions and ensure to monitor factors such as the convenience of shopping from anywhere, display the wide variety of your products available in the most effective manner, and  increase trust in online transactions.",
            servicesType:[
                "ECommerce Store development","E Commerce consulting", "Conversion ratio optimisation- CRO" 
            ]
        },
        {
            serviceId: "/services/B2BLeadGeneration",
            serviceName: "B2B Lead Generation.",
            serviceExplanation: "We run  lead generation campaigns, and you will most likely be dealing with the entire spectrum of leads from cold (never heard of you before) through to red hot (ready to jump straight on a call). Unlike referrals, outbound leads may take more nurturing before becoming a client.",
            servicesType:[
                "Funnel building","EMail Marketing","Whatsapp / SMS Marketing","Linkedin Marketing ( Lead Generation )" 
 
            ]
        },
        {
            serviceId: "/services/performanceMarketing",
            serviceName: "Performance Marketing.",
            serviceExplanation: "There is no one-size-fits-all strategy for social media. That is why we work with you to develop a deep understanding of your business goals and target audience. Our team of expert strategists will brainstorm and craft a social media strategy that aligns with your brand’s mission, creating a roadmap to success.",
            servicesType:[
                "Paid ads Marketing","Social Media Marketing Management","SEO","Website Metrics","Google Ads Management" 
            ]
        },
        {
            serviceId: "https://www.nucreative.co.uk/contact-us",
            serviceName: "Influencer Marketing.",
            serviceExplanation: "Find Influencers. Boost ROI. Dominate Your Market. Unlock the untapped potential of your brand by harnessing the influence of 250+ creators. Amplify your ROI and establish unrivalled market domination.",
            servicesType:[
                "Youtube Influencer Marketing", "Instagram Influencer Marketing" ,"Barter with Micro Influencer","Meme Marketing","On-demand UGC Creation","Celebrity Endorsement"
            ]
        },
        {
            serviceId: "/services/videoProduction",
            serviceName: "Videos.",
            serviceExplanation: "Video production is the most entertaining and engaging marketing tool available in today’s market. It’s a unique form of storytelling that includes visual images, text, music, and audio to convey your message to future buyers. At MatchPoint, we specialise in showing your brand to the world to elevate your marketing strategy.",
            servicesType:[
                "Corporate Movies","Product demo videos","Campaign Videos","Profile Videos","Documentary Videos"
            ]
        }
      ],
      howWeDoSection:{
        heading: "How we do it?",
        body:"Our process is marked by checkpoints and tools that permit fast, effective and accountable engagements. Our clients always know what is happening in their marketing.So rely your story on us. The wins, struggles, and dreams. You know your business better than anyone. Help us know it, too.",
      }
  };
  
  export default data;
  