import React from 'react';
import '../../styles/common.css' 
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { Link } from 'react-router-dom';

function OutlineButton(props) {
    return (
        <div className={props.className}>
            <div className='outlined-button d-flex justify-content-around'>
                <Link className="outlined-button-text px-2" to={props.redirectTo} style={{ textDecoration: 'none', color: 'inherit' }}>{props.buttonName}</Link>
                <ArrowOutwardIcon style={{ color: 'black' }} />
            </div>
        </div>
    );
}

export default OutlineButton;