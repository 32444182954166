import React from 'react';
import '../../styles/common.css';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { Link } from 'react-router-dom';

function SolidColorButton(props) {
    return (
        <div className={props.className}>
            <button className='solid-color-button d-flex justify-content-center'>
                <Link className="solid-button-text px-2" to={props.redirectTo} style={{ textDecoration: 'none'}}>{props.buttonName}</Link>
                <ArrowOutwardIcon style={{ fontWeight: 'normal', fontSize:"150%"}} />
            </button>
        </div>
    );
}

export default SolidColorButton;