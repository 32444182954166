import React, { useEffect, useRef,useState } from 'react';
import { gsap } from 'gsap';
import '../../styles/FAQ.css';
import CloseIcon from '@mui/icons-material/Close';
import { useMediaQuery } from '@mui/material';

function FAQSection(props) {

    const isMobile = useMediaQuery('(max-width: 768px)');

    useEffect(() => {

        gsap.to(".FQA-header", {
            color: "#cccccc",
            y: -10,
            duration: 1,
            scrollTrigger: {
              trigger: ".FQA-header",
              start: "top 60%",
              toggleActions: "play reverse play reverse",
            },
          });
        }
)

    const [activeIndex, setActiveIndex] = useState(0);
    const [rotateAngle, setRotateAngle] = useState(45);


    const QAsectionClick = (Currentindex) =>{
        setActiveIndex(Currentindex);
        setRotateAngle(0);
    }

    return (
        <div className='FAQ-section'>
            {console.log(props, "========")}
            <div className='FAQ-container'>
                {
                    props.contentData.map((item, index) => {
                        return (
                            <div key={index} className={`QA-section ${index === activeIndex ? 'active-QA-section' : ''}`} onClick={() => QAsectionClick(index)}>
                               <div className='d-flex flex-row align-items-center justify-content-center'>
                                    <div className="col-1 col-md-1 mx-2 text-center">
                                        {
                                            index === activeIndex ?
                                            <CloseIcon
                                            color="black"
                                            sx={{
                                                fontSize: isMobile ? 100 : 200,
                                                border: "none"
                                            }}
                                            style={{ fontSize: isMobile ? '100%' : '200%' }}
                                        />
                                                :
                                                <CloseIcon
                                                    color="black"
                                                    sx={{
                                                        fontSize: isMobile ? 100 : 200,
                                                        border: "none",
                                                        transform: `rotate(45deg)`,
                                                    }}
                                                    style={{ fontSize: isMobile ? '100%' : '200%' }}
                                                />
                                        }
                                    </div>
                                    <div className='col-12 col-md-8 '>
                                         <p data-index={index + 1} className={`${index === activeIndex ? 'active-question' : 'FAQ-question'}`} style={{margin: "0" }}>{item.categoryName}</p>
                                    </div>
                                   
                                </div>
                                <div className={` ${index === activeIndex ? 'active-answer' : 'FAQ-answer'}`}>{item.explanation}</div>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
}

export default FAQSection;

