import React from 'react';
import "../styles/contactUs.css"

function ContactUs() {
    return (
        <div className='contact-us-section container-fluid'>
            <div className='row justify-content-center mt-5'>
                <div className='col-10 col-md-5'>
                    <div className='address-detail-section detail-section-card mt-3 p-4'>
                        <h4 className='cu-detail-heading'>Chennai Office</h4>
                        <p className='cu-detail-content'>
                            37, East Abhiramapuram,<br/> Mylapore, Chennai,<br/> Tamil Nadu 600004
                        </p>
                        <h4 className='cu-detail-heading'>Bengalure Office</h4>
                        <p className='cu-detail-content'>
                            Samrah Plaza,<br/>St Mark's Rd,<br/> Bengaluru 560001
                        </p>
                    </div>
                </div>
                <div className='col-10 col-md-4 my-3'>
                    <iframe className="map-container" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1368.6758235178572!2d80.26121301844219!3d13.034812202179145!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52670ca9a52e15%3A0xac120fdfebb10997!2sContique%20Global%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1709922519273!5m2!1sen!2sin" 
                        allowfullscreen="" 
                        loading="lazy" 
                        referrerpolicy="no-referrer-when-downgrade">
                    </iframe>
                </div>

                <div className='contact-detail-section detail-section-card col-10 col-md-9 row mt-3 p-4 mb-5'>
                    <div className='col-12 col-md-6'>
                        <h4 className='cu-detail-heading'>Write us at</h4>
                        <p className='cu-detail-content mb-4'>support@contiqueglobal.com</p>
                    </div>
                    <div className='col-12 col-md-6'>
                        <h4 className='cu-detail-heading'>Dial us at</h4>
                        <p className='cu-detail-content'>+91 893 984 7012</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactUs;

