import React from 'react';
import '../styles/aboutUs.css'
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import BrowseGalleryIcon from '@mui/icons-material/BrowseGallery';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import BusinessIcon from '@mui/icons-material/Business';

function AboutUs() {
    return (
        <div className='about-us-page'>
            <div className='row justify-content-center'>
            <div className='about-us-header col-12 text-center'>Grow 10X faster,<br/>Better, Smarter </div>
            <div className='about-para col-10 col-md-9'>We as an Agency/a team grew from roots as an advertising agency so we know what it takes to grow a brand. Come to experience the right mix of developing websites and marketing strategies with digital campaigns that's curated and served right just for your brand.</div>
            </div>
            <div className='about-us-benifits-section row container-fluid justify-content-center mx-0'>
                <div className='au-benifit col-11 col-md-10 row justify-content-center'>
                    <div className='benifit-text col-11 col-md-7 flex-column'>
                        The power of Action
                        <div className='benefit-detail-content col-11 col-md-11'>Clear goals = clear results. Based on data, statistics and your short term and long term goals, we craft sales objectives and calculate the leads/sales required to reach them alongside a detailed SWOT.</div>
                    </div>
                    <AccessTimeFilledIcon className= "au-benefit-image col-2 col-md-2" sx={{fontSize:130, width: 200, alignContent: 'center'}}/>
                    
                </div>
                <div className='au-benifit col-11 col-md-10 row justify-content-center'>
                    <div className='benifit-text col-11 col-md-7 flex-column'>
                        Knowing your consumer Profile
                        <div className='benefit-detail-content col-11 col-md-11'>We deep-dive into the demographics and psychographics of your dream customers and market to understand the most effective ways to reach them and speak to them.</div>
                    </div>
                    <PeopleAltIcon className= "au-benefit-image col-2 col-md-7" sx={{fontSize:130, width:200}}/>
                </div>
                <div className='au-benifit col-11 col-md-10 row justify-content-center'>
                    <div className='benifit-text col-11 col-md-7 flex-column'>
                        Your branding talks about your brand culture
                        <div className='benefit-detail-content col-11 col-md-11'>Your brand becomes conversion-ready when it's more than a logo and colours. We turn your brand into a style, voice and aesthetics that become recognisable, make some noise, and connect with customers.</div>
                    </div>
                    <BusinessIcon className= "au-benefit-image col-2 col-md-2" sx={{fontSize:130, width:200}}/>
                </div>
                <div className='au-benifit col-11 col-md-10 row justify-content-center'>
                    <div className='benifit-text col-11 col-md-7 flex-column'>
                        We ensure to deploy the perfect plan
                        <div className='benefit-detail-content col-11 col-md-11'>A perfectly articulated unique selling proposition and cleverly communicated brand is then ready to embark on a ballsy marketing journey via detailed steps, timing and a touch of creative genius.</div>
                    </div>
                    <BrowseGalleryIcon className= "au-benefit-image col-2 col-md-2" sx={{fontSize:130, width:200}}/>
                </div>
            </div>
            <div className='about-us-card col-md-9 col-10'>
                <div className='au-card-header col-12 col-md-12 py-5'>How we make you stand out</div>
                <div className='au-card-body col-12 col-md-12'>World-class digital should meet real-world challenges. Our multi-skilled teams have a depth of experience in both designing and deploying digital projects at scale.</div>
            </div>
        </div>
       
    );
}

export default AboutUs;