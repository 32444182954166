const serviceDataJson = {
  "customApplication": {
    heading: "Custom Application Page.",
    serviceTagLine: "Unleash the Power of Innovation through Technical Mastery",
    serviceImageUrl: "",
    serviceText: "Let’s turn your smart software idea into a smart business idea. Bring it to life with a partner that understands software and business",
    serviceCategory: [
      {
        categoryName: "Web Application Building",
        explanation: "We’re designers. We’re coders. We’re software developers. We create web applications with rich and consistent experiences across all devices. Developing software that integrates existing business processes with operations in order to drive productivity and heighten reliability through automation."
      },
      {
        categoryName: "Android/ IOS application development",
        explanation: "Providing mobile app solutions to businesses that are looking to extend the reach and further the brand experience of how prospects and customers interact with available platforms. Utilising strategic workshops, design sprints and build iterations we drive our clients and customers towards accessing information more efficiently, optimising operations, and sparking bottom line growth."
      },
      {
        categoryName: "Strategy Development",
        explanation: "We work along with you to Know the target market for your application, researching competitors and their offerings. While defining the scope of the project. We work to understand and decide on what features are needed for success. Assessing the project timeline, overall development needs, and the budget."
      },
      {
        categoryName: "Support & Performance Monitoring",
        explanation: "By taking support and performance monitoring you can track and analyse critical business transactions, trace errors across various microservices, and understand the impact of external components on your application's performance."
      }
    ],
    BenefitsHeading: "You understand that an application is the right thing to do.",
    ourbenefits: [
      {
        benefitHeader: "Operational reliability",
        benefitBody: "We work on the ability of your application to continue operating in the expected way over time, accommodating all requirements ensuring it doesn’t crash when heals itself at times of disruptions and there is no single point of failure."
      },
      {
        benefitHeader: "Application Usability",
        benefitBody: "We thrive to improve trust, work on creating customer satisfaction and reduce support costs as well through our team of experienced developers."
      },
      {
        benefitHeader: "Solution Elegance",
        benefitBody: "The elegance of the application speaks of how well the solution fits the problem addressed, the space that the application has created for itself amongst your customers and how coherent the solution is, is exactly what we do at Contique."
      },
      {
        benefitHeader: "Product Scalability",
        benefitBody: "Designing a product such that we ensure scalability of the system to either handle increases in load without impact on the performance of the system to accommodate the growing demands of your business and your customer base in mind."
      }
    ]
  },
  "websiteBuilding": {
    heading: "Website building",
    serviceTagLine: "Developed for performance. Designed to delight. Delivered for growth.",
    serviceImageUrl: "",
    serviceText: "You’re here because you’ve got a challenge that needs solving - on a budget, time and resource. So Let your vision merge with our expertise and let’s create a design that we will never compromise.",
    serviceCategory: [
      {
        categoryName: "Website Development",
        explanation: "Whether you’re a start-up or large organisation, we design custom websites that deliver results. A standout website needs to do more than just look good. It also needs to take a user-focused approach. User-focused means being structured and laid out to maximise the user experience. Also If you already have an existing website, we can give it a thorough review with your business, customers and market needs in mind. We can then provide you with recommendations for improvement and implement these changes for you to drive positive results."
      },
      {
        categoryName: "Wordpress Site Development",
        explanation: "We provide end-to-end WordPress development services to startups, entrepreneurs, and small to medium businesses across all industries. Our experienced WordPress developers build high-performing websites and web applications that are easy to manage. Our innovation-driven approaches for custom WordPress website development services help you to reinvent your online presence with speed and agility and meet all your expectations. So get your side modernised today with no hassle of maintenance through wordpress."
      },
      {
        categoryName: "Shopify Store Development",
        explanation: "We’ve had the pleasure of building some really wild things with Shopify that even we didn’t think were possible- until a client asked us to do it. Interactive, responsive tools with real time visual rendering? You got it. We love a challenge, and Shopify allows you to set one as crazy as you’d like. With multi-talented design and development experts, we can leverage decades of cumulative experience to make your design goals a reality."
      },
      {
        categoryName: "Landing Page Development",
        explanation: "Landing pages are the foundation of your website. We design striking, results-driven landing pages with a single goal in mind: to prompt your audience to take immediate action. Our landing page layout serves a powerful entry point to your website — helping you to make a great first impression on users, educate and inform them on your brand, and guide them to conversion with strategically placed call-to-actions — whether it’s signing up for your email newsletter, contacting you through a form, or making a purchase."
      },
      {
        categoryName: "UI / UX Design",
        explanation: "Understanding what you are building, why it is being built, and how we are going to leverage your brand experience with our expert UX / UI design. Each phase of our project process is meant to provide clear and concise deliverables and act as a milestone. And we understand that in today's digital landscape, it is more important than ever to have a website that stands out and effectively represents your brand. That's why our team of highly skilled and experienced web designers and developers is committed to creating visually stunning and user-friendly websites that not only look great, but also function effectively to help you achieve your business goals."
      }
    ],
    BenefitsHeading: "You understand that a website is a positive investment.",
    ourbenefits: [
      {
        benefitHeader: "A customised approach",
        benefitBody: "We'll work with you to create a user experience website and design that suits your unique business needs."
      },
      {
        benefitHeader: "Acquire More Lead",
        benefitBody: "Your website works as a tool to convert organic visitors into qualified leads. We’ll help you develop landing pages that are laser-focused, compelling and that motivate readers to take action."
      },
      {
        benefitHeader: "Better User Experience",
        benefitBody: "A great website is designed with the end user in mind, ensuring a smooth and enjoyable experience. Professional websites are optimised for all devices."
      },
      {
        benefitHeader: "Ongoing support",
        benefitBody: "We can support you after the build with regular updates, evaluating your website’s uptime, and ensuring your website is secure."
      }
    ]
  },
  "ECommerceGrowth": {
    heading: "E-commerce growth",
    serviceTagLine: "Ready to transform your business? Elevate your business with expert ecommerce development.",
    serviceImageUrl: "",
    serviceText: "Every business is unique, and so is our approach. We focus on creating intuitive, engaging, and visually appealing e-commerce sites that offer seamless user experiences.",
    serviceCategory: [
      {
        categoryName: "ECommerce Store development",
        explanation: "We develop high-performing, secure and scalable web applications across webs. Our talented and highly skilled technical team will use the latest technologies, development methodologies, and best practices to ensure that each project is executed with precision, efficiency, and an unwavering focus."
      },
      {
        categoryName: "ECommerce Consulting",
        explanation: "We stretch to consult that ensures to cover all areas of online business. Whether you’re looking to revamp your website to boost sales, find the best tools for managing your site, or even to connect your digital presence to your brick and mortar stores with omnichannel strategy, we’ve got you covered, completely."
      },
      {
        categoryName: "Conversion Ratio Optimization (CRO)",
        explanation: "CRO means conversion rate optimization which is a system for increasing the number of visitors who turn into customers. ROI, or return on investment, is closely linked to CRO as it tells you where your money is going in your digital marketing strategy. Our job as your conversion rate optimization agency is to find out what’s stopping visitors from staying on your website and converting into customers and then coming up with solutions to these barriers."
      }
    ],
    BenefitsHeading: "You understand that an ECommerce store is done right.",
    ourbenefits: [
      {
        benefitHeader: "Create unique shopping experiences",
        benefitBody: "We create unique Online experiences that are able to directly impact the customer journey by creating a unique shopping journey through well designed and structured pages."
      },
      {
        benefitHeader: "User-Friendly Navigation",
        benefitBody: "The key to helping customers find the products they need quickly is to offer a user-friendly navigation system. At Contique we provide just that through well placed UI/UX logically categorised. The navigation channel reflects a well handled E store and this is exactly what we deliver."
      },
      {
        benefitHeader: "An easy to use checkout layout",
        benefitBody: "A checkout page isn’t exactly a shopping cart - they both serve different purposes and stages in the e-commerce process. The step is key in converting people browsing your site into actual buyers, playing a big role in shaping their shopping experience."
      },
      {
        benefitHeader: "Flexibility for your customers",
        benefitBody: "Flexibility in e-commerce is all about brands being able to meet customers wherever they may be, across any device or touchpoint. We provide one unified solution that can provide consistency and agility for any use case, from the pop-up shop, till checkout and the online delivery experience, seamlessly."
      }
    ]
  },
  "B2BLeadGeneration": {
    heading: "B2B lead Generation",
    serviceTagLine: "Leads driven based on real user behaviour. We are your one-stop-shop for B2B leads, audiences, and enrichment.",
    serviceImageUrl: "",
    serviceText: "Never sulk again since you will enjoy fresh leads every day.",
    serviceCategory: [
      {
        categoryName: "Funnel Building",
        explanation: "We're starting a movement to help businesses just like you— big or small, new or established, struggling or successful — to learn and grow together in a revolutionary digital community. With our strategic marketing process, everything ties back to your revenue and profit goals. When you're ready - we will increase website traffic, get qualified leads, and drive you towards closing projects."
      },
      {
        categoryName: "Email Marketing",
        explanation: "As a full-service email marketing agency to meet all your marketing needs, we Use the latest tools and technologies to ensure your campaigns are efficient and effective. Our detailed analytics and reporting allow you to track your campaign performance and make informed decisions. Partner with us, and benefit from a team dedicated to boosting your online reputation and sales."
      },
      {
        categoryName: "Whatsapp / SMS Marketing",
        explanation: "Reach anyone, anywhere, instantly with messaging solutions tailored to your business goals. Across industries, using our proven method of co-creation, we work side-by-side with you to understand your needs, design your strategy, and test and exceed operational, technical, and regulatory requirements."
      },
      {
        categoryName: "Linkedin Marketing (Lead Generation)",
        explanation: "LinkedIn is the social community of choice for B2B companies. The platform offers a great deal of customization, and it’s a great tool for prospecting, as well as building brand awareness and recognition. A strategic approach to LinkedIn Advertising includes understanding who you are trying to connect with, and what they perceive to be a pain point."
      }
    ],
    BenefitsHeading: "You understand that B2B lead generation is fixed right.",
    ourbenefits: [
      {
        benefitHeader: "Increased Market Presence",
        benefitBody: "Social media is one of the best ways to promote your business to a wider audience, and the use of platforms such as Instagram, Facebook, Twitter, and LinkedIn has really helped to develop branding of large and also small companies. At Contique, we look forward to putting you in the limelight in front of your audience."
      },
      {
        benefitHeader: "More Business Opportunities",
        benefitBody: "Leads can bring a host of new business opportunities. Engaging in lead generation marketing will enable you to meet a variety of new people from a variety of settings. These relationships could lead to referrals or meeting others that may want to use your services."
      },
      {
        benefitHeader: "Increased Following",
        benefitBody: "Through social media and the use of content, you can help to increase your following and help with engagement by telling your story. Your following on social media is great to promote products and services too."
      },
      {
        benefitHeader: "Increased Revenue",
        benefitBody: "By generating leads, we create an opportunity to do this continuously. Increasing your revenue through lead generation on multiple channels is one of the best ways to reach your target audience, and we turn all tables around to sort it out for you."
      }
    ]
  },
  "performanceMarketing": {
    heading: "Performance Marketing",
    serviceTagLine: "Connecting you, to your audiences across all that you do. We sit at the centre of Brand, Customer and Technology. We bring to life and connect ideas within this ever-growing digital world and ensure real customer and business value is achieved not just today or tomorrow but into the future.",
    serviceImageUrl: "",
    serviceCategory: [
      {
        categoryName: "Paid Ads Marketing (Meta Ads Management, Linkedin Ads Management, Shopify Ads Management)",
        explanation: "Your brand needs more than an organic reach to help you reach out to more prospective customers and increase the buzz and engagement around your products or services. Our expert PPC agency handles the paid marketing for you. We understand every nitty-gritty detail about how pay-per-click might be the perfect answer to your promotional woes."
      },
      {
        categoryName: "Social Media Marketing Management (SMM)",
        explanation: "We create brands that rise above the noise of their marketplace to find sustainable and unmistakable differentiation. Our fresh perspective allows us not only to see the challenges and opportunities of our client’s product or company but to remain clearly focused on the future we envision together. While creating social marketing we make sure that the goal for it is clear. Hire us for social marketing services and our team will make sure you stand out of the crowd with your own social media followers."
      },
      {
        categoryName: "SEO (On Page SEO / Off Page SEO)",
        explanation: "SEO services are all about getting your website listed as the first result on Google when someone searches for your product or service. The basic model for this goal is the higher the position your website is in search, equals a higher click rate which results in a higher number of conversions. But remember, unlike Pay per click this is a long term investment. After understanding your company, to ensure our SEO services are a good fit, we start implementing the work right away."
      },
      {
        categoryName: "Website Metrics",
        explanation: "We help track and analyse the performance and effectiveness of your website, providing valuable insights for improving your online presence as well as user experience, understanding whether you’re meeting your goals with your website, thereby Optimising your site for improved performance, to keep Informed of future decisions and directions, demonstrate ROI incline, at all times."
      },
      {
        categoryName: "Google Ads Management (Google Shopping Ads, Youtube Ads, Display Ads)",
        explanation: "As a Digital Marketing Agency, we’ve been providing PPC Management, Our team will focus on the AdWords management team will begin setup of your account that involves: keyword research, landing page discovery, building campaigns, building ad groups, writing ads, maximising the use of extensions, adding negative keywords, setting goals, setting budgets and more."
      }
    ],
    BenefitsHeading: "You understand that Performance Marketing is targeted right.",
    ourbenefits: [
      {
        benefitHeader: "Increased Reach",
        benefitBody: "Performance marketing comprises several strategies and tapping into these different channels, especially on platforms with large numbers of users, provides businesses with a rich pool of potential customers. At Contique, our team of analysts just drive through to deliver this and continue to get it better."
      },
      {
        benefitHeader: "Real-time Tracking",
        benefitBody: "It is always apt to measure performance marketing metrics and be transparent with the progress of each campaign, from click-throughs to conversions we look forward to getting the details shared and reviewed to get the metrics stronger."
      },
      {
        benefitHeader: "Lower Risk",
        benefitBody: "We assist you to plan how much to spend and start with low-risk strategies. And as we ensure to get the campaigns right and structured we sure look to rocket your brand to greater heights."
      },
      {
        benefitHeader: "Flexible Strategies",
        benefitBody: "Performance marketing is customizable, and we look forward to getting it right for your business requisites after measuring the metrics, and focus on partners and channels that provide high ROIs while stopping those that did not perform, leaving room to try other strategies all through."
      }
    ]
  },
  "videoProduction": {
    heading: "Video Production",
    serviceTagLine: "Fresh, engaging video content made easy. We combine experienced storytelling with go-getting spirit, drawing from a background in film-making, digital marketing, journalism, and PR to bring great content to life.",
    serviceImageUrl: "",
    serviceCategory: [
      {
        categoryName: "Corporate Movies",
        explanation: "If your brand has a story to tell, we’ll help you bring it to life through rich, engaging video that connects through emotion and creativity. We have a scalable servicing model to suit any size budget and you can take advantage of the full creative and strategic services."
      },
      {
        categoryName: "Product Demo Videos",
        explanation: "Brand or profile videos create an emotional connection between your brand and your potential customers. And there’s no better way to make that emotional connection than with a well-told story. A well-crafted brand video can make for a great first impression, give your business a human face in a way that words alone cannot, and reassure a prospective client that you are the right person to solve their problem. Brand videos can be shared on your website, across social media, and even showcased at trade events."
      },
      {
        categoryName: "Profile Videos",
        explanation: "Need an interview, talking head, or client testimonial video? We have you covered with a professional team. Having worked with an incredibly diverse range of people from different ethnicities, professions, age groups, and with differing levels of physical ability, we have the experience and sensitivity to ensure your interviewee feels comfortable enough to give a convincing monologue about your business or service. Building this rapport is one of the things we do best. Our team knows how to put a subject at ease and provide the right direction."
      },
      {
        categoryName: "Documentary Videos",
        explanation: "We’re a full-service production company and a world-class post house. This means we work with brands, agencies, and producers to create incredible results across the full spectrum of filmmaking, content creation, and post-production services."
      }
    ],
    BenefitsHeading: "You understand that video creation is done right.",
    ourbenefits: [
      {
        benefitHeader: "Video Familiarizes Your Personality",
        benefitBody: "It's tough for audiences to become familiar with your brand amongst all the content they visualize. Videos, however, enable your audience to get acquainted with your unique personality, thereby rapidly increasing familiarity and credibility."
      },
      {
        benefitHeader: "Clips and Footage Increase Visibility",
        benefitBody: "An observer is more likely to adopt watching an audiovisual narrative than by reading it. Videos create a lasting impression all through in the background as soon as visual messages pop to your target audience. We at Contique love to resonate with your market, tag along with your audience, and create a laying impression all through."
      },
      {
        benefitHeader: "Video Makes Learning Convenient",
        benefitBody: "Promoting a personal brand, product, or service in a format that produces enough quality click-throughs, Digital audiences prefer to consume videos, and a big reason why we prefer to stream into the videos section is that they're easy, convenient, and painless for your target audience to connect with your story, your brand, and your persona."
      },
      {
        benefitHeader: "Videos Work Well with Device Screens",
        benefitBody: "Attention-grabbing content often requires the use of multimedia formats like videos. With videos, we serve your spectators well by showing relevant, timely, and excellent content that they are most likely to interact with, at all times."
      }
    ]
  }
}

export default serviceDataJson;
